<template>
  <div>
    <base-page
      :title="$t('saleManage.title')"
      :formData="formData"
      :config="config"
      @functionMethod="functionMethod"
      @search="search"
    >
      <table-list
        ref="tableList"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.saleList"
        :columns="columns"
        v-model="tableRow"
      >
        <!-- 创建时间 -->
        <template v-slot:createTime="{ row }">
          {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
        </template>
        <!-- 销售单号 -->
        <template v-slot:sn="{ row }">
          {{ row.sale.sn }}
        </template>
        <!-- 设备信息 -->
        <template v-slot:equipment="{ row }">
          {{ row.sale.equipment.name }}
        </template>
        <!-- 客户信息 -->
        <template v-slot:customer="{ row }">
          {{ row.sale.customer.name }}
        </template>
        <!-- 出厂日期 -->
        <template v-slot:manufactureTime="{ row }">
          {{ $util.format(row.sale.manufactureTime, 'yyyy-MM-dd') }}
        </template>
        <!--  质保日期  -->
        <template v-slot:warrantyTime="{ row }">
          {{ $util.format(row.sale.warrantyTime, 'yyyy-MM-dd') }}
        </template>
      </table-list>
      <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
    </base-page>
    <!-- 详情 -->
    <export-detail
      v-if="showExportDetail"
      :tableRow="tableRow"
      @closePage="closePage"
    />
    <!--删除记录  -->
    <!-- 复制时弹出的设备、备件、故障列表 -->
    <dialog-table-list
      ref="dialogTableList"
      width="1200px"
      v-if="showDialogTableList"
      :title="$t('functionEnum.SALE_LIST_DELETE')"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.saleListDelete"
      :columns="dialogColumns"
      :formData="dialogFormData"
      :showConfirm="false"
      :config="dialogConfig"
      v-model="dialogTableRow"
      @search="dialogSearch"
      @cancel="showDialogTableList = false"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd hh:mm:ss') }}
      </template>
    </dialog-table-list>
  </div>
</template>

<script>
import Detail from './detail'
import ExportDetail from './exportDetail'
import { importFile } from '@/utils/ajax'

export default {
  components: {
    Detail,
    ExportDetail,
  },
  data() {
    return {
      // 搜索
      formData: {},
      config: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 10,
          tagProps: {
            placeholder: this.$t('saleManage.nameOrNotes'),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 10,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表格页
      tableRow: null,
      columns: [
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
        // lang:出厂编号
        {
          label: this.$t('saleManage.equipmentNos'),
          prop: 'equipmentNo',
        },
        // lang:销售单号
        {
          label: this.$t('saleManage.sn'),
          prop: 'sn',
        },
        // lang:设备信息
        {
          label: this.$t('saleManage.equipment'),
          prop: 'equipment',
        },
        // lang:客户信息
        {
          label: this.$t('saleManage.customer'),
          prop: 'customer',
        },
        // lang:使用部门
        {
          label: this.$t('saleManage.useDept'),
          prop: 'useDept',
          callback(row) {
            return row.sale.useDept
          },
        },
        // lang:出厂日期
        {
          label: this.$t('saleManage.manufactureTime'),
          prop: 'manufactureTime',
        },
        // lang:质保日期
        {
          label: this.$t('saleManage.warrantyTime'),
          prop: 'warrantyTime',
        },
        // lang:备注
        {
          label: this.$t('base.notes'),
          prop: 'notes',
        },
      ],
      // 表单页
      showDetail: false,
      // 导出详情
      showExportDetail: false,
      // 删除记录----
      showDialogTableList: false,
      dialogColumns: [
        // lang:删除时间
        {
          label: this.$t('saleManage.deleteTime'),
          prop: 'createTime',
        },
        // lang:出厂编号
        {
          label: this.$t('saleManage.equipmentNos'),
          prop: 'equipmentNo',
        },
        // lang:销售单号
        {
          label: this.$t('saleManage.sn'),
          prop: 'sn',
          callback(row) {
            return row.sale.sn
          },
        },
        // lang:设备信息
        {
          label: this.$t('saleManage.equipment'),
          prop: 'equipment',
          callback(row) {
            return row.equipment.name
          },
        },
        // lang:客户信息
        {
          label: this.$t('saleManage.customer'),
          prop: 'customer',
          callback(row) {
            return row.sale.customer.name
          },
        },
        // lang:使用部门
        {
          label: this.$t('saleManage.useDept'),
          prop: 'useDept',
          callback(row) {
            return row.sale.useDept
          },
        },
        // lang:出厂日期
        {
          label: this.$t('saleManage.manufactureTime'),
          prop: 'manufactureTime',
          callback: (row) => {
            return this.$util.format(row.sale.manufactureTime, 'yyyy-MM-dd')
          },
        },
        // lang:质保日期
        {
          label: this.$t('saleManage.warrantyTime'),
          prop: 'warrantyTime',
          callback: (row) => {
            return this.$util.format(row.sale.warrantyTime, 'yyyy-MM-dd')
          },
        },
      ],
      dialogTableRow: null,
      // 选择设备搜索
      dialogFormData: {},
      dialogConfig: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 9,
          tagProps: {
            placeholder: this.$t('saleManage.nameOrNotes'),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 11,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
    }
  },
  methods: {
    // 弹出设备查询
    dialogSearch() {
      const dialogFormData = this.$util.copyData(this.dialogFormData)
      this.$refs.dialogTableList.searchList(dialogFormData)
    },
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      this.showExportDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      this.$refs.tableList.searchList(this.formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (method !== 'add' && method !== 'deleteRecord' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'add':
        case 'edit':
          this.showDetail = true
          break
        case 'view':
          this.showExportDetail = true
          break
        case 'del':
          this.deleteMethod()
          break
        case 'deleteRecord':
          this.showDialogTableList = true
          break
        case 'export':
          this.exportMethod()
          break
        default: // do something
      }
    },
    // 删除
    deleteMethod() {
      this.$element
        .showConfirm({
          content: this.$t('base.deleteConfirm'),
        })
        .then(() => {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.saleDelete,
            data: this.tableRow.sale,
          }).then(() => {
            // lang:删除成功
            this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
            this.search()
          })
        })
    },
    // 导出
    exportMethod() {
      const params = {
        qrcodeSize: 170,
        logoSize: 10,
        fontWidth: 12,
        clo: 3,
        pageCount: 3,
      }
      params.saleId = this.tableRow.sale.id
      importFile({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.saleExportSaleCode,
        // lang:销售二维码
        name: this.$t('saleManage.saleCode'),
        type: '.docx',
        data: params,
      })
    },
  },
}
</script>