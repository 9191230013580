<template>
  <div>
    <dialog-form-list
      :config="config"
      :formData="formData"
      @cancel="cancel"
      @confirm="confirm"
      @openDialog="openDialog"
    />
    <!-- 弹出表格 -->
    <dialog-table-list
      width="1200px"
      v-if="showDialogTableList"
      ref="tableList"
      :title="dialogTitle"
      :url="dialogUrl"
      :columns="dialogColumns"
      :formData="dialogFormData"
      :config="dialogConfig"
      :defaultParams="dialogDefaultParams"
      v-model="dialogTableRow"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
      @search="dialogSearch"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <template v-slot:level="{ row }">
        {{ levelNames[row.level] }}
      </template>
    </dialog-table-list>
  </div>
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    const cmd = this.$store.state.cmd
    return {
      cmd,
      formData: {},
      config: [
        // lang:设备信息
        {
          tag: 'dialog-define',
          label: this.$t('saleManage.equipment'),
          prop: 'equipment',
          rules: {
            noNull: true,
          },
          tagEvents: {
            // 显示名称
            showName(data) {
              return data && data.name
            },
          },
        },
        // lang:客户信息
        {
          tag: 'dialog-define',
          label: this.$t('saleManage.customer'),
          prop: 'customer',
          rules: {
            noNull: true,
          },
          tagEvents: {
            // 显示名称
            showName(data) {
              return data && data.name
            },
          },
        },
        // lang:使用部门
        {
          label: this.$t('saleManage.useDept'),
          prop: 'useDept',
          rules: {
            noNull: true,
          },
        },
        // lang:出厂日期
        {
          tag: 'el-date-picker',
          label: this.$t('saleManage.manufactureTime'),
          prop: 'manufactureTime',
          rules: {
            noNull: true,
          },
        },
        // lang:质保日期
        {
          tag: 'el-date-picker',
          label: this.$t('saleManage.warrantyTime'),
          prop: 'warrantyTime',
          rules: {
            noNull: true,
          },
        },
        // lang:销售数量
        {
          label: this.$t('saleManage.quantity'),
          prop: 'quantity',
          hidden: cmd !== 'add',
          rules: {
            noNull: true,
            type: 'positiveInt',
          },
          tagProps: {
            disabled: cmd !== 'add',
          },
        },
        // lang:出厂编号
        {
          label: this.$t('saleManage.equipmentNos'),
          prop: 'equipmentNos',
          hidden: cmd !== 'add',
          rules: {
            noNull: true,
          },
          tagProps: {
            placeholder: this.$t('saleManage.addEquipmentNos'),
          },
        },
        // lang:备注
        {
          label: this.$t('base.notes'),
          prop: 'notes',
        },
      ],
      // 弹出表格
      showDialogTableList: false,
      dialogTitle: '',
      dialogUrl: '',
      dialogDefaultParams: {},
      dialogColumns: [],
      dialogFormData: {},
      dialogConfig: {},
      dialogTableRow: null,
      levelNames: {
        ONE: this.$t('equipmentManage.equipmentInfo'),
        TWO: this.$t('equipmentManage.partInfo'),
        THREE: this.$t('equipmentManage.faultInfo'),
      },
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 确认表格弹框
    dialogConfirm(row) {
      this.formData[this.targetProp] = row
      this.showDialogTableList = false
    },
    // 打开表格弹框
    openDialog(item) {
      this.targetProp = item.prop
      this.dialogTableRow = this.formData[item.prop] || null
      if (item.prop === 'equipment') {
        // 选择设备
        this.dialogTitle = this.$t('saleManage.equipment')
        this.dialogUrl = '/' + this.$util.getLocalStorage('apiprefix') + this.$api.equipmentFind
        this.dialogDefaultParams = {
          companyUid: this.$store.getters.company.uid,
          scope: 'COMPANY',
        }
        this.dialogColumns = [
          // lang:类型
          {
            label: this.$t('equipmentManage.type'),
            prop: 'level',
            width: '140px',
          },
          // lang:所属分组
          {
            label: this.$t('equipmentManage.equipmentGroup'),
            prop: 'equipmentGroup',
            callback(row) {
              return row.equipmentGroup?.name
            },
          },
          // lang:所属部门
          {
            label: this.$t('equipmentManage.equipmentUserAccountGroup'),
            prop: 'userAccountGroup',
            callback(row) {
              return row.userAccountGroup?.name
            },
          },
          // lang:名称
          {
            label: this.$t('equipmentManage.name'),
            prop: 'name',
          },
          // lang:编号
          {
            label: this.$t('equipmentManage.number'),
            prop: 'number',
          },
          // lang:型号
          {
            label: this.$t('equipmentManage.model'),
            prop: 'model',
          },
          // lang:规格
          {
            label: this.$t('equipmentManage.specifications'),
            prop: 'specifications',
          },
        ]
        this.dialogConfig = [
          {
            label: this.$t('base.searchStr'),
            prop: 'searchStr',
            span: 9,
            tagProps: {
              placeholder: this.$t('equipmentManage.nameOrNotes'),
            },
          },
          {
            tag: 'el-date-picker',
            label: this.$t('base.createTime'),
            prop: 'createTime',
            span: 11,
            className: 'daterangeDate',
            tagProps: {
              type: 'daterange',
              startPlaceholder: this.$t('base.startDate'),
              endPlaceholder: this.$t('base.endDate'),
            },
          },
        ]
      } else {
        // 选择客户
        this.dialogTitle = this.$t('saleManage.customer')
        this.dialogUrl = '/' + this.$util.getLocalStorage('apiprefix') + this.$api.customerList
        this.dialogDefaultParams = {}
        this.dialogColumns = [
          // lang:创建时间
          {
            label: this.$t('base.createTime'),
            prop: 'createTime',
          },
          // lang:客户名称
          {
            label: this.$t('customerManage.name'),
            prop: 'name',
          },
          // lang:部门
          {
            label: this.$t('customerManage.department'),
            prop: 'department',
          },
          // lang:负责人
          {
            label: this.$t('customerManage.director'),
            prop: 'director',
          },
          // lang:联系方式
          {
            label: this.$t('customerManage.contactsInformation'),
            prop: 'contactsInformation',
          },
        ]
        this.dialogConfig = [
          {
            label: this.$t('base.searchStr'),
            prop: 'searchStr',
            span: 9,
            tagProps: {
              placeholder: this.$t('customerManage.nameOrNotes'),
            },
          },
          {
            tag: 'el-date-picker',
            label: this.$t('base.createTime'),
            prop: 'createTime',
            span: 11,
            className: 'daterangeDate',
            tagProps: {
              type: 'daterange',
              startPlaceholder: this.$t('base.startDate'),
              endPlaceholder: this.$t('base.endDate'),
            },
          },
        ]
      }
      this.showDialogTableList = true
    },
    // 关闭表格弹框
    dialogCancel() {
      this.showDialogTableList = false
    },
    // 表格弹框搜索
    dialogSearch() {
      const dialogFormData = this.$util.copyData(this.dialogFormData)
      this.$refs.tableList.searchList(dialogFormData)
    },
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow.sale)
          // 处理出厂日期
          this.formData.manufactureTime = this.$util.format(
            this.formData.manufactureTime,
            'yyyy-MM-dd'
          )
          // 处理质保日期
          this.formData.warrantyTime = this.$util.format(
            this.formData.warrantyTime,
            'yyyy-MM-dd'
          )
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      if (this.cmd === 'add') {
        formData.equipmentNos = formData.equipmentNos.split(',')
        // 验证销售数量和出厂编号是否保持一致
        if (+formData.quantity !== formData.equipmentNos.length) {
          this.$element.showMsg(this.$t('saleManage.inconformity'), 'warning')
          return
        }
      }
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'edit':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      // 出厂日期
      formData.manufactureTime = new Date(formData.manufactureTime).getTime()
      // 质保日期
      formData.warrantyTime = new Date(formData.warrantyTime).getTime()
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.saleAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      })
    },
    // 修改
    editMethod(formData) {
      const tableRow = {
        id: this.tableRow.sale.id,
        manufactureTime: new Date(formData.manufactureTime).getTime(),
        warrantyTime: new Date(formData.warrantyTime).getTime(),
      }
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.saleUpdate,
        data: tableRow,
      }).then(() => {
        // lang:修改成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$emit('closePage', 'edit')
      })
    },
  },
}
</script>
